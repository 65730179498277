/* Animation de clignotement pour l'icone de nouveautés */
@keyframes blink {
    0% {
        opacity: .2;
    }
    
    100% {
        opacity: 1;
    }
}

.news-available {
    animation: blink .7s infinite alternate;
}