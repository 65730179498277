/*******************************
         Site Overrides
*******************************/

#root, #app {
    height: 100%;
}

#app {
    display: flex;
    flex-direction: column;
}